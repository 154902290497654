<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';
import Gen from '@helper/Gen';
import draggable from 'vuedraggable'

export default {
	name: "BoItemProduct",
	extends: Base,
	components: {
	   draggable,
	},
	data(){
	return {
		Name:"BoItemProduct",
		category: {},
		type: {},
		brand: {},
		elem: [],
		inputCat:{mpc_parent_id:'',}
	}
	},
	mounted(){
		this.$set(this.$root, 'page', this)
		this.refreshData()
	},
	watch:{
		'$route.query'(){
			this.refreshData()
		},
		'filter.level'(){
			this.search()
		},
	},
	methods:{
		endDrag(){
            BOGen.apirest('/'+this.Name, {data:this.data.data,type:'sort'}, (err,resp)=>{
            console.log(resp)
            }, "POST");
        },
        selectType(e){
        	console.log(e)
        	BOGen.apirest('/'+this.Name, {id:e.val,type:'chaneType'}, (err,resp)=>{
            this.type=resp.data
            }, "POST");
        },
        addCategory(){
        	BOGen.apirest('/'+this.Name, {data:this.inputCat,type:'addCategory'}, (err,resp)=>{
	            if(resp.error==1){
	            	return Gen.info(resp.message, "danger",3000,'.modal-info')
	            } else {
	            	return Gen.info(resp.message, "success", 1000,'.modal-info').then(()=>{
			            $('#prod_cat').modal('hide')
			            setTimeout(()=>{
			            	this.row.mpn_cat_id = resp.cat
			            	this.inputCat.mpc_parent_id = resp.cat
			            },300)
			            this.refreshData()
			         })
	            	
	            }            
            }, "POST");
        },
        addType(){
        	this.inputCat.mpc_type='T'
        	BOGen.apirest('/'+this.Name, {data:this.inputCat,type:'addType'}, (err,resp)=>{
	            if(resp.error==1){
	            	return Gen.info(resp.message, "danger",3000,'.modal-info')
	            } else {
	            	return Gen.info(resp.message, "success", 1000,'.modal-info').then(()=>{
			            $('#prod_type').modal('hide')
			            this.elem.val = resp.cat
			            this.selectType(this.elem.val)
			            setTimeout(()=>{
			            	this.row.mpn_cat_id = resp.cat
			            	this.row.mpn_type_id = resp.type
			            },300)
			            
			         })
	            }            
            }, "POST");
        },
        addBrand(){
        	BOGen.apirest('/'+this.Name, {data:this.inputCat,type:'addBrand'}, (err,resp)=>{
	            if(resp.error==1){
	            	return Gen.info(resp.message, "danger",3000,'.modal-info')
	            } else {
	            	return Gen.info(resp.message, "success", 1000,'.modal-info').then(()=>{
			            $('#brand').modal('hide')
			            this.brand = resp.brand
			            setTimeout(()=>{
			            	this.row.mpn_brand_id = resp.mb_id
			            },500)
			            
			         })
	            }            
            }, "POST");
        },
	}
}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		
		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
             <div class="card">
                <div class="card-body">
	                <VForm @resp="search">
						<div class="row">
							<div class="col-sm-5">
								<h5 class="card-title">{{ObjectName}} List</h5>
							</div>
							<div class="col-sm-3">
							</div>
							<div class="col-sm-3">
								<div class="form-group mb-0">
                                    <div class="input-group">
                                       <input type="text" v-model="filter.search"  class="form-control" placeholder="Search...">
                                       <div class="input-group-append">
                                          <button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
                                       </div>
                                    </div>
                                 </div>
							</div>
							<div class="col-sm-1">
								<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
							</div>
						</div>
					</VForm>
                </div>
                <div class="table-responsive ajax-table">
					<table class="table table-striped table-bordered">
						<thead>
							<tr>
								<th>#</th>
								<th>Categories</th>
								<th>Product Type</th>
								<th>Brand</th>
								<th>Item Name</th>
								<th>Status</th>
								<th width="120px">Action</th>
							</tr>
						</thead>
						<draggable v-model="data.data" tag="tbody" @end="endDrag" style="cursor:pointer;">
							<tr v-for="(v,k) in data.data" :key="k">
								<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
								<td>{{v.cat}}</td>
								<td>{{v.type}}</td>
								<td>{{v.brand}}</td>
								<td>{{v.mpn_name}}</td>
								<td><StatusLabel :status="v.status"></StatusLabel></td>
								<td class="btn-action">
									<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
									<a href="javascript:;" class="icon_action" @click="changeStatus(k)" v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
									<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
								</td>
							</tr>
							<tr v-if="NotFound">
								<td colspan="99">
									<h3 class="tc">{{NotFound}}</h3>
								</td>
							</tr>
							<tr v-if="data.data===false">
								<td colspan="99">
									<LoadingSpinner light></LoadingSpinner>
								</td>
							</tr>
						</draggable>
					</table>
				</div>
				<div class="panel-body">
					<div class="pull-right">
						<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
             </div>
          </div>
       </div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
                      <div class="col-md-8">
                         <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
                      </div>
                   </div>
					<div class="info"></div>
					<div class="row">
						<div class="col-md-6">
	                        <BoField name="mpn_cat_id">
	                            <div class="input-group">
	                               	<select2 name="mpn_cat_id" v-bind="validation('mpn_cat_id')" @change="selectType($event)" :options="category" :object="['mpc_id','mpc_name_en']" v-model="row.mpn_cat_id">
										<option value="">-- Select Category --</option>
									</select2>
	                               <div class="input-group-append">
	                                  <button data-toggle="modal" data-target="#prod_cat" class="btn btn-secondary" type="button" onclick="education_fields();"><i class="fas fa-plus"></i></button>
	                               </div>
	                            </div>
	                        </BoField>
                      	</div>
                      	<div class="col-md-6" v-if="type.length">
                             <BoField name="mpn_type_id">
                                <div class="input-group">
                                   <select2 name="mpn_type_id" v-bind="validation('mpn_type_id')" :options="type" :object="['mpc_id','mpc_name_en']" v-model="row.mpn_type_id">
										<option value="">-- Select Type --</option>
									</select2>
                                   <div class="input-group-append">
                                      <button data-toggle="modal" data-target="#prod_type" class="btn btn-secondary" type="button" onclick="education_fields();"><i class="fas fa-plus"></i></button>
                                   </div>
                                </div>
                             </BoField>
                        </div>
	                    <div class="col-md-6">
	                        <BoField name="mpn_brand_id">
	                            <div class="input-group">
	                               	<select2 name="mpn_brand_id" v-bind="validation('mpn_brand_id')" :options="brand" :object="['mb_id','mb_name']" v-model="row.mpn_brand_id">
										<option value="">-- Select Brand --</option>
									</select2>
	                               <div class="input-group-append">
	                                  <button data-toggle="modal" data-target="#brand" class="btn btn-secondary" type="button" onclick="education_fields();"><i class="fas fa-plus"></i></button>
	                               </div>
	                            </div>
	                        </BoField>
	                    </div>
						<div class="col-md-6">
						 	<BoField name="mpn_name" :attr="{placeholder:'e.g. PINOT GRIGIO 2016'}" v-model="row.mpn_name"></BoField>
						</div>
	                      <div class="col-sm-3">
	                         <BoField name="mpn_is_active">
								<div class="row">
									<radio name="mpn_is_active" v-model="row.mpn_is_active" option="Y" :attr="validation('mpn_is_active')">Active</radio>
									<radio name="mpn_is_active" v-model="row.mpn_is_active" option="N">Inactive</radio>
								</div>
							</BoField>
	                      </div>
						<div class="col-12">
							<div class="text-left">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit <i class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
		<!-- modal -->
		<div id="prod_cat" class="modal" tabindex="-1" role="dialog" aria-labelledby="prod_cat" aria-hidden="true">
		   <div class="modal-dialog">
		      <div class="modal-content">
		         <div class="modal-header">
		            <h4 class="modal-title" id="prod_cat">Product Categories</h4>
		            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
		         </div>
				<VForm @resp="addCategory">
		         <div class="modal-body">
		            <div class="row">
		               <div class="col-md-12">
		                <BoField name="mpc_image">
							<Uploader name="mpc_image" :param="{thumbnail:true}"  type="categories" uploadType="cropping" v-model="inputCat.mpc_image"></Uploader>
						</BoField>	
		               </div>
		               <div class="col-md-12 mt-2">
		                  <div class="form-group">
		                    <BoField name="mpc_name_en" mandatory :attr="{required:'required',placeholder:'e.g. Wine',minlength:3,maxlength:100}" v-bind="validation('mpc_name_en')" v-model="inputCat.mpc_name_en"></BoField>
							<BoField name="mpc_name_id" :attr="{placeholder:'e.g. Wine',minlength:3,maxlength:100}" v-model="inputCat.mpc_name_id"></BoField>
		                  </div>
		                  <div class="modal-info"></div>
		               </div>
		            </div>
		         </div>
		         <div class="modal-footer">
		            <button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
		         </div>
		         
		     	</VForm>
		      </div>
		      <!-- /.modal-content -->
		   </div>
		   <!-- /.modal-dialog -->
		</div>

		<div id="prod_type" class="modal" tabindex="-1" role="dialog" aria-labelledby="prod_type" aria-hidden="true">
			<div class="modal-dialog">
			    <div class="modal-content">
			         <div class="modal-header">
			            <h4 class="modal-title" id="prod_type">Product Type</h4>
			            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
			         </div>
			         <VForm @resp="addType">
			         <div class="modal-body">
			            <div class="row">
			               <div class="col-md-12 mt-2">
			                  <div class="form-group">
			                     <label class="control-label">Categories <span class="text-danger mr5">*</span></label>
			                     <div class="input-group">
			                        <select2 name="mpc_parent_id" mandatory v-bind="validation('mpc_parent_id')" @change="selectType($event)" :options="category" :object="['mpc_id','mpc_name_en']" v-model="inputCat.mpc_parent_id">
										<option value="">-- Select Category --</option>
									</select2>
			                     </div>
			                  </div>
			               </div>
			               <div class="col-md-12 mt-2">
			                  	<BoField :label="'Type Name (EN)'" name="mpc_name_en" mandatory :attr="{required:'required',placeholder:'e.g. Champagne',minlength:3,maxlength:100}" v-bind="validation('mpc_name_en')" v-model="inputCat.mpc_name_en"></BoField>
								<BoField :label="'Type Name (ID)'" name="mpc_name_id" :attr="{placeholder:'e.g. Wine',minlength:3,maxlength:100}" v-model="inputCat.mpc_name_id"></BoField>
								<div class="modal-info"></div>
			               </div>
			            </div>
			         </div>
			         <div class="modal-footer">
			            <button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
			         </div>
			     	</VForm>
			    </div>
	   		</div>
		</div>

		<div id="brand" class="modal" tabindex="-1" role="dialog" aria-labelledby="prod_type" aria-hidden="true">
			<div class="modal-dialog">
			    <div class="modal-content">
			         <div class="modal-header">
			            <h4 class="modal-title" id="prod_type">Brand</h4>
			            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
			         </div>
			         <VForm @resp="addBrand">
			         <div class="modal-body">
			            <BoField name="mb_name" :attr="{placeholder:'e.g. Taltarni'}" v-model="inputCat.mb_name"></BoField>
			         </div>
			         <div class="modal-footer">
			            <button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
			         </div>
			     	</VForm>
			    </div>
	   		</div>
		</div>
	</div>
</template>
